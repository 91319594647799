import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"
import PostponeNotice from "../../components/eri/PostponeNotice"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/eri.css"

import JSONData from "../../../content/eri/eri-data.json"

import Chappel from "../../../src/data/images/pages/eagle-river-institute/sidebar.jpg"
import VisibilityIcon from '@material-ui/icons/Visibility';
function ERI () {
	

	let sidebarData = JSONData.sidebarTextblocks;

	let subheaderData = JSONData.subheader; 

	console.log(sidebarData)
	
	const location = ""
	
	const content = sidebarData.map((element,key)=> <div key={key}><b>{element.header}</b> {element.body}<br/><br/></div>)
	return (
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content eri-colors eri">
					<div >
						<Subheader data={subheaderData} />
					</div>
					<a className="alert" href="https://eagleriverinstitute.org" className="banner" style={{display:"inline-block",backgroundColor:"darkBlue",fontWeight:"bold", color:"yellow", padding:"10px", width:"calc(100% - 20px)"}} target="_blank">
						<span style={{display:"flex","alignItems":"center"}}> <VisibilityIcon style={{marginLeft:"25px",marginRight:"10px"}}/> Visit our Eagle River Institute Website  <ArrowForwardIcon style={{marginLeft:"5px",marginRight:"10px"}}/></span>
					</a>
					<img src={Chappel} style={{padding:"10px",width:"250px",display:"inline-block", verticalAlign:"top"}}/>
					<div className="content" style={{minWidth:"350px",margin:"10px",display:"inline-block", width:"calc(100% - 410px)"}}>
					{content}
					</div>
			

			</main>
			<Footer />		
		</div>
	)
}

export default ERI 