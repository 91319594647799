import React, {Component} from 'react'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';



function PostponeNotice(props){

	return (
						<h3 className="eri-postponed">
							<ErrorOutlineIcon className="error-icon" style={{ fontSize: 40 }}/>
							<p>
								Due to coronavirus travel restrictions, we are cancelling the Eagle River Institute for 2020. All of our instructors, however, have already agreed to address the same topics next summer, August 1-5, 2021. May God allow us to be safely together then. We hope you make plans to join us.
							</p>
						</h3>
	)	
}

export default PostponeNotice 